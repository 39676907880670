<template>
  <div class="relative">
    <DefaultInput
      :label="label"
      :id="id"
      :type="hidePassword ? 'password' : 'text'"
      class="mt-2"
      :placeholder="placeholder"
      :required="required"
      v-model="passwordValue"
      @update:modelValue="onChange"
    />

    <button
      class="w-fit absolute top-1/2 right-7 translate-y-1"
      @click.prevent="showPassword"
    >
      <span class="material-icons">{{
        hidePassword ? "visibility_off" : "visibility"
      }}</span>
    </button>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  label?: string;
  id: string;
  placeholder?: string;
  required?: boolean;
}>();

const emit = defineEmits<{
  (e: "update:passwordValue", value: string): void;
}>();

const hidePassword = ref(true);
const passwordValue = ref("");

const showPassword = () => {
  hidePassword.value = !hidePassword.value;
};

const onChange = (value: string) => {
  emit("update:passwordValue", value);
};
</script>
